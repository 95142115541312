.Support-Header {
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(4pt + 2vmin);
  color: white;
  padding-left: 20pt;
}
